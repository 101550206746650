import { useContext } from 'react';

import { BillingIntervals } from '@jaramba-frontend/core/types';

import { CheckoutContext } from '../contexts';
import { CheckoutActions } from '../types';
import type { CheckoutErrors, ProductData } from '../types';

const useCheckoutContext = () => {
    const { state, dispatch } = useContext(CheckoutContext);

    if (state === undefined) {
        throw new Error('useCheckoutContext must be used within a Context');
    }

    const setEmail = (email: string) => {
        dispatch({ type: CheckoutActions.SetEmail, payload: email });
    };

    const setBillingInterval = (billingInterval: BillingIntervals) => {
        dispatch({ type: CheckoutActions.SetBillingInterval, payload: billingInterval });
    };

    const setToken = (token: string) => {
        dispatch({ type: CheckoutActions.SetToken, payload: token });
    };

    const setUid = (uid: number) => {
        dispatch({ type: CheckoutActions.SetUid, payload: uid });
    };

    const setProductInfo = (productInfo: ProductData) => {
        dispatch({ type: CheckoutActions.SetProductInfo, payload: productInfo });
    };

    const setPaymentSuccess = (isPaymentSucceed: boolean) => {
        dispatch({ type: CheckoutActions.SetPaymentSuccess, payload: isPaymentSucceed });
    };

    const setErrors = (errors: CheckoutErrors) => {
        dispatch({ type: CheckoutActions.SetErrors, payload: errors });
    };

    const resetContextState = () => {
        dispatch({ type: CheckoutActions.SetEmail, payload: null });
        dispatch({ type: CheckoutActions.SetBillingInterval, payload: BillingIntervals.Monthly });
        dispatch({ type: CheckoutActions.SetToken, payload: null });
        dispatch({ type: CheckoutActions.SetErrors, payload: null });
    };

    return {
        uid: state.uid,
        email: state.email,
        billingInterval: state.billingInterval,
        token: state.token,
        productInfo: state.productInfo,
        isPaymentSucceed: state.isPaymentSucceed,
        errors: state.errors,
        setEmail,
        setUid,
        setBillingInterval,
        setToken,
        setProductInfo,
        setPaymentSuccess,
        setErrors,
        resetContextState,
    };
};

export default useCheckoutContext;
