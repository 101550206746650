import { PropsWithChildren, Suspense, lazy, useLayoutEffect } from 'react';

import { Header } from '@jaramba-frontend/core/components';
import { CONSENT_INIT_SCRIPT } from '@jaramba-frontend/core/constants';
import { useAnalytics, useTimedPageRefresh } from '@jaramba-frontend/core/hooks';

import Footer from '../Footer';
import styles from './styles.module.scss';

const ConsentBanner = lazy(() => import('@jaramba-frontend/core/components/ConsentBanner'));

const AppLayout = ({ children }: PropsWithChildren) => {
    useTimedPageRefresh();
    useAnalytics();

    useLayoutEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = CONSENT_INIT_SCRIPT;
        document.head.prepend(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <div className={styles.root}>
            <Suspense fallback={null}>
                <ConsentBanner />
            </Suspense>
            <Header />
            <div className={styles.content}>{children}</div>
            <Footer />
        </div>
    );
};

export default AppLayout;
