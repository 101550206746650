import { useNavigate } from 'react-router-dom';

import { PageNotFound } from '@jaramba-frontend/core/components';

import { routes } from '../../constants';

const NotFound = () => {
    const navigate = useNavigate();

    const handleRedirectHome = () => {
        navigate(routes.SIGNUP, {
            replace: true,
        });
    };

    return <PageNotFound onHomeButtonClick={handleRedirectHome} />;
};

export default NotFound;
