import type { AxiosError } from 'axios';

import type { UtmData } from '@jaramba-frontend/core/types';

import { apiService } from '.';
import type { CreateUserRequest, CreateUserResponse, User } from '../types';

export const createUser = async (email: string, password: string, marketingData?: UtmData): Promise<User> => {
    try {
        const res = await apiService.post<CreateUserResponse, CreateUserRequest>(
            '/webapp/accounts/create',
            {
                UserName: email.trim(),
                Password: password.trim(),
                UTMCampaign: marketingData?.campaign ?? undefined,
                UTMContent: marketingData?.content ?? undefined,
                UTMMedium: marketingData?.medium ?? undefined,
                UTMSource: marketingData?.source ?? undefined,
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        return Promise.resolve({ email: res?.Name, token: res?.Token, uid: res?.Id });
    } catch (err: any) {
        return Promise.reject(err as AxiosError);
    }
};
