import { UtmParams } from '../constants';
import type { UtmData } from '../types';

export const extractMarketingUTMData = (params: URLSearchParams): UtmData | null => {
    if (Object.values(UtmParams).every((utmParam) => !params.get(utmParam))) {
        return null;
    }

    const extractedData = {
        ...(params.get(UtmParams.UTM_SOURCE) && { source: params.get(UtmParams.UTM_SOURCE) ?? undefined }),
        ...(params.get(UtmParams.UTM_CONTENT) && { content: params.get(UtmParams.UTM_CONTENT) ?? undefined }),
        ...(params.get(UtmParams.UTM_MEDIUM) && { medium: params.get(UtmParams.UTM_MEDIUM) ?? undefined }),
        ...(params.get(UtmParams.UTM_CAMPAIGN) && { campaign: params.get(UtmParams.UTM_CAMPAIGN) ?? undefined }),
    };

    return extractedData;
};
