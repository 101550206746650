export interface CreateUserAccountForm {
    email: string;
    password: string;
    confirmPassword: string;
    acceptPolicy: boolean;
}

export interface CreateUserResponse {
    Id: number;
    Name: string;
    Token: string;
    RefreshToken: string;
    TokenExpirationDate: string;
    Roles: string[];
}

export interface CreateUserRequest {
    UserName: string;
    Password: string;
    WantNewsLetter?: boolean;
    WantMailReminder?: boolean;
    UTMCampaign?: string;
    UTMContent?: string;
    UTMMedium?: string;
    UTMSource?: string;
}

export interface CheckoutSessionResponse {
    redirectToUrl: string;
}

export interface CheckoutSessionRequest {
    PriceLookupKey: string;
    Options: {
        SuccessUrl: string;
        CancelUrl: string;
    };
    campaignId?: string;
}

export interface User {
    uid: number;
    email: string;
    token: string;
}

export interface ProductData {
    campaignName: string;
    monthlyPrice: string;
    annualPrice: string;
    monthlyLookupKey: string;
    annualLookupKey: string;
    trialEndDate?: string;
    offerText?: string;
}

export type CheckoutErrors = { [key: string]: string[] } | null;

export enum CheckoutActions {
    SetEmail,
    SetErrors,
    SetUid,
    SetBillingInterval,
    SetToken,
    SetProductInfo,
    SetPaymentSuccess,
}
