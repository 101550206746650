import { useSearchParams } from 'react-router-dom';

import { Paper } from '@jaramba-frontend/core/components';
import { BillingIntervals } from '@jaramba-frontend/core/types';

import { useCheckoutContext } from '../../hooks';
import type { ProductData } from '../../types';
import styles from './styles.module.scss';

type Props = Partial<ProductData>;

const ProductInfo = ({ campaignName, monthlyPrice, annualPrice, trialEndDate, offerText }: Props) => {
    const [searchParams] = useSearchParams();
    const { billingInterval } = useCheckoutContext();
    const isAnnualBilling = billingInterval === BillingIntervals.Annualy;
    const isSpecifiedCampaign = searchParams.has('campaignName');
    const campaignNameParam = isSpecifiedCampaign && campaignName ? `/${campaignName}` : '';

    return (
        <Paper className={styles.container}>
            <div className={styles.content}>
                <p className={styles.title}>Ditt val av prenumeration:</p>
                <h3 className={styles.price}>
                    {isAnnualBilling ? `Årligen (${annualPrice})` : `Månad (${monthlyPrice})`}
                </h3>

                {offerText && <p className={styles.offer}>{offerText}</p>}
                {!offerText && trialEndDate && (
                    <p className={styles.offer}>
                        Gratis fram till {trialEndDate}, därefter {isAnnualBilling ? annualPrice : monthlyPrice}
                    </p>
                )}
            </div>

            <a href={`${process.env.REACT_APP_WEBSITE_URL}/products${campaignNameParam}`} className={styles.buttonLink}>
                Ändra prenumeration
            </a>
        </Paper>
    );
};

export default ProductInfo;
