import CN from 'clsx';
import { forwardRef, ReactElement } from 'react';

import checkmarkIcon from '@jaramba-frontend/core/assets/icons/checkmark.svg';

import styles from './styles.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string | ReactElement;
    className?: string;
    ring?: 'square' | 'round';
    error?: string | null;
}

const Checkbox = forwardRef(
    ({ error = null, label, className = '', ring = 'square', ...rest }: Props, ref: React.Ref<HTMLInputElement>) => (
        <label className={CN(styles.label, { [className]: className })}>
            <div className={styles.wrapper}>
                <input readOnly={!rest.onChange} className={styles.input} ref={ref} type="checkbox" {...rest} />
                <div
                    className={CN(
                        styles.checkbox,
                        { [styles.isRound]: ring === 'round' },
                        { [styles.withError]: !!error }
                    )}
                >
                    <img src={checkmarkIcon} draggable={false} />
                </div>
                {label && <span className={styles.text}>{label}</span>}
            </div>
            {error && <div className={styles.error}>{error}</div>}
        </label>
    )
);

export default Checkbox;
