import { useEffect } from 'react';

import { ReactComponent as AppStoreIcon } from '@jaramba-frontend/core/assets/icons/appStore.svg';
import { ReactComponent as GooglePlayIcon } from '@jaramba-frontend/core/assets/icons/googlePlay.svg';
import qrCodeBanner from '@jaramba-frontend/core/assets/images/qr-code-app-banner.jpg';
import { Button } from '@jaramba-frontend/core/components';
import { appStoreLinks } from '@jaramba-frontend/core/constants';
import { AnalyticsService } from '@jaramba-frontend/core/services';
import { BillingIntervals } from '@jaramba-frontend/core/types';

import { useCheckoutContext } from '../../hooks';
import styles from './styles.module.scss';

const Success = () => {
    const { token, uid, productInfo, isPaymentSucceed, billingInterval, resetContextState } = useCheckoutContext();

    useEffect(() => {
        if (isPaymentSucceed) {
            const getPriceValue = () => {
                const priceText =
                    billingInterval === BillingIntervals.Annualy ? productInfo?.annualPrice : productInfo?.monthlyPrice;

                return Number(priceText?.replace(/\D/g, ''));
            };

            AnalyticsService.tagEvents([
                {
                    event: 'userId',
                    userId: String(uid),
                },
                {
                    event: 'subscription',
                    category: 'checkout',
                    type: billingInterval,
                    currency: 'SEK',
                    value: getPriceValue(),
                    label: `User successfully purchased ${billingInterval} subscription.`,
                },
                {
                    event: `subscription_${billingInterval}`,
                    category: 'checkout',
                },
                {
                    ecommerce: {
                        purchase: {
                            actionField: {
                                id: `initial_payment_${uid}`,
                            },
                        },
                    },
                },
            ]);
        }
    }, []);

    const handleRedirectToParentPortal = () => {
        resetContextState();
        AnalyticsService.tagEvents([
            {
                event: 'parent_portal_redirect',
                category: 'checkout',
                action: 'PARENT_PORTAL_BUTTON_CLICK',
                label: 'User clicks on Parent Portal button.',
            },
        ]);

        window.location.href = `${process.env.REACT_APP_PARENT_PORTAL_URL}/login?token=${token}` ?? '';
        return;
    };

    return (
        <div className={styles.pageContent}>
            <h1 className={styles.title}>Välkommen till Jaramba</h1>

            <p className={styles.subtitle}>
                Utforska en rolig kunskapsvärld fylld av äventyr – <br /> ladda ner dina appar och kom igång direkt.
            </p>

            <img src={qrCodeBanner} className={styles.qrBanner} draggable={false} alt="Application QR code banner." />

            <p className={styles.mobileOS}>iOS + Android</p>

            <p className={styles.text}>
                För att ladda ner appen, scanna QR koden med den enhet du vill ladda ner appen på.
            </p>

            <div className={styles.storeButtons}>
                <a
                    href={appStoreLinks.appStore}
                    className={styles.storeButton}
                    title="AppStore button"
                    target="_blank"
                    rel="noreferrer"
                >
                    <AppStoreIcon />
                </a>
                <a
                    href={appStoreLinks.googlePlay}
                    className={styles.storeButton}
                    title="Google Play button"
                    target="_blank"
                    rel="noreferrer"
                >
                    <GooglePlayIcon />
                </a>
            </div>

            <Button label="Ta mig till portalen" onClick={handleRedirectToParentPortal} />
        </div>
    );
};

export default Success;
