import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Input, Checkbox, Button, Loading, Paper } from '@jaramba-frontend/core/components';
import { inputPasswordValidationRules } from '@jaramba-frontend/core/constants';
import { validateEmail } from '@jaramba-frontend/core/utils';

import type { CheckoutErrors, CreateUserAccountForm } from '../../types';
import styles from './styles.module.scss';

interface Props {
    isFormSubmitting: boolean;
    isLoadingCampaign: boolean;
    responseErrors: CheckoutErrors;
    onEmailChange: () => void;
    onSubmit: (email: string, password: string, hasInputErrors: boolean) => Promise<void>;
}

const CreateUserForm = ({ isFormSubmitting, isLoadingCampaign, responseErrors, onEmailChange, onSubmit }: Props) => {
    const {
        formState: { errors },
        register,
        handleSubmit,
        getValues,
        watch,
    } = useForm<CreateUserAccountForm>({
        reValidateMode: 'onChange',
        defaultValues: { email: '', password: '', confirmPassword: '', acceptPolicy: false },
    });

    const emailWatcher = watch('email');
    const hasInputErrors = Object.values(errors).length > 0 ? Object.values(errors).every((value) => !!value) : false;
    const email = watch('email').trim();
    const password = watch('password').trim();

    useEffect(() => {
        onEmailChange();
    }, [emailWatcher]);

    return (
        <Paper className={styles.formContainer}>
            <h1 className={styles.title}>Skapa konto</h1>
            <form
                className={styles.form}
                onSubmit={handleSubmit(async () => await onSubmit(email, password, hasInputErrors))}
            >
                <Input
                    {...register('email', {
                        required: 'Epostadress är obligatoriskt',
                        validate: {
                            isEmail: (value) => (validateEmail(value) ? true : 'Felaktigt e-postformat eller domän'),
                        },
                    })}
                    label="Ange din e-postadress"
                    type="email"
                    error={errors?.email?.message}
                    disabled={isFormSubmitting}
                    placeholder="email@jaramba.se"
                    autoComplete="username"
                />
                <Input
                    {...register('password', {
                        required: 'Lösenord är obligatoriskt',
                        ...inputPasswordValidationRules,
                    })}
                    label="Välj lösenord"
                    type="password"
                    placeholder="**********"
                    error={errors?.password?.message}
                    disabled={isFormSubmitting}
                    autoComplete="new-password"
                />
                <Input
                    {...register('confirmPassword', {
                        required: 'Bekräfta lösenord är obligatoriskt',
                        ...inputPasswordValidationRules,
                        validate: {
                            valid: (value) =>
                                value === getValues('password') ? true : 'Lösenorden stämmer inte överens',
                        },
                    })}
                    label="Bekräfta lösenord"
                    type="password"
                    placeholder="**********"
                    disabled={isFormSubmitting}
                    error={errors?.confirmPassword?.message}
                />
                <Checkbox
                    {...register('acceptPolicy', {
                        required: 'Användarvillkoren måste godkännas för att gå vidare',
                    })}
                    label={
                        <>
                            Jag godkänner Jarambas{' '}
                            <a href="https://www.jaramba.se/villkor/" target="_blank" rel="noreferrer">
                                användarvillkor
                            </a>
                            .
                        </>
                    }
                    disabled={isFormSubmitting}
                    error={errors?.acceptPolicy?.message}
                />

                {responseErrors && (
                    <ul className={styles.responseErrorsContainer}>
                        {Object.keys(responseErrors)?.map((fieldName: string) =>
                            responseErrors[fieldName]?.map((message: string, index: number) => (
                                <li key={`${message}_${index}`} className={styles.responseError}>
                                    {message}
                                </li>
                            ))
                        )}
                    </ul>
                )}

                {isFormSubmitting && <Loading className={styles.loadingContainer} />}

                <Button
                    label="Fortsätt"
                    className={styles.submitButton}
                    disabled={isLoadingCampaign || isFormSubmitting || !!responseErrors}
                    type="submit"
                    fullWidth
                />
            </form>
        </Paper>
    );
};

export default CreateUserForm;
