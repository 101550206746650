import { SharedApiService } from '@jaramba-frontend/core/services';

const getJarambaClient = async () => {
    return {
        'jaramba-client': process.env.REACT_APP_JARAMBA_CLIENT,
    };
};

export const apiService = new SharedApiService([getJarambaClient]);

export * from './registration';
