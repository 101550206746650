import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { routes } from '../../constants';
import { useCheckoutContext } from '../../hooks';

const PrivateRoute = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { billingInterval, email, token, isPaymentSucceed, setPaymentSuccess } = useCheckoutContext();

    useEffect(() => {
        const hasSuccessParam = searchParams.get('paymentSucceed') === '1';

        if (location.pathname.includes(routes.SUCCESS) && hasSuccessParam) {
            setPaymentSuccess(true);
        }

        if (email && token && billingInterval && !isPaymentSucceed) {
            navigate(
                {
                    pathname: routes.PAYMENT,
                    search: location.search,
                },
                {
                    replace: true,
                }
            );
        } else if (email && token && billingInterval && isPaymentSucceed) {
            navigate(
                {
                    pathname: routes.SUCCESS,
                    search: '',
                },
                {
                    replace: true,
                }
            );
        } else {
            navigate(
                {
                    pathname: routes.SIGNUP,
                    search: location.search,
                },
                {
                    replace: true,
                }
            );
        }
    }, [location.pathname, location.search]);

    return <Outlet />;
};

export default PrivateRoute;
