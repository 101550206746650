import { useState } from 'react';

import { stripeCheckoutSession } from '@jaramba-frontend/core/api';
import { Button, Loading, Paper } from '@jaramba-frontend/core/components';
import { AnalyticsService } from '@jaramba-frontend/core/services';
import { BillingIntervals } from '@jaramba-frontend/core/types';

import { ProductInfo } from '../../components';
import { routes } from '../../constants';
import { useCheckoutContext } from '../../hooks';
import styles from './styles.module.scss';

const Payment = () => {
    const { token, productInfo, billingInterval } = useCheckoutContext();
    const [hasError, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleOpenStripeCheckout = async () => {
        if (productInfo?.campaignName) {
            AnalyticsService.tagEvent({
                event: 'open_stripe_checkout_from_payment_page',
                category: 'checkout',
                action: 'OPEN_STRIPE_CHECKOUT_FROM_PAYMENT_PAGE',
                label: 'User clicks on button to open Stripe checkout from payment page.',
            });

            try {
                setLoading(true);
                const redirectUrl = await stripeCheckoutSession({
                    token: token as string,
                    successUrl: `${window.location.origin}${routes.SUCCESS}?paymentSucceed=1`,
                    cancelUrl: `${window.location.origin}${routes.PAYMENT}${window.location.search}`,
                    priceLookupKey:
                        billingInterval === BillingIntervals.Annualy
                            ? productInfo.annualLookupKey
                            : productInfo.monthlyLookupKey,
                    campaignId: productInfo.campaignName,
                });

                if (redirectUrl) {
                    window.location.href = redirectUrl;
                } else {
                    setError(true);
                }
            } catch (error) {
                setLoading(false);
                setError(true);
                console.error(error);
                throw new Error('Stripe Checkout session request error');
            }
        }
    };

    return (
        <div className={styles.pageContent}>
            <ProductInfo
                campaignName={productInfo?.campaignName ?? ''}
                monthlyPrice={productInfo?.monthlyPrice ?? ''}
                annualPrice={productInfo?.annualPrice ?? ''}
                trialEndDate={productInfo?.trialEndDate ?? ''}
                offerText={productInfo?.offerText}
            />

            <Paper className={styles.paymentCard}>
                <h1 className={styles.title}>Varför behöver vi dina kortuppgifter?</h1>
                <div className={styles.infoCard}>
                    <p>
                        Vi behöver dina kortuppgifter för att slutföra registeringen av ditt Jaramba-konto.{' '}
                        {productInfo?.trialEndDate && (
                            <>
                                Du betalar ingenting förrän din provperiod går ut: <b>{productInfo.trialEndDate}</b>
                            </>
                        )}
                    </p>
                </div>
                {loading ? (
                    <Loading className={styles.loading} />
                ) : (
                    <Button label="Gå vidare" onClick={handleOpenStripeCheckout} />
                )}

                {hasError && <p className={styles.errorMessage}>Begäran misslyckades. Vänligen försök igen senare.</p>}
            </Paper>
        </div>
    );
};

export default Payment;
