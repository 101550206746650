import styles from './styles.module.scss';

const Footer = () => {
    return (
        <div className={styles.footer}>
            <a
                href={`${process.env.REACT_APP_WEBSITE_URL}/terms-and-conditions`}
                target="_blank"
                rel="noopener noreferrer"
            >
                Allmänna Villkor
            </a>
            <a
                href={`${process.env.REACT_APP_WEBSITE_URL}/policy#Vadarencookie`}
                target="_blank"
                rel="noopener noreferrer"
            >
                Cookie Policy
            </a>
            <a href={`${process.env.REACT_APP_WEBSITE_URL}/policy`} target="_blank" rel="noopener noreferrer">
                Integritetspolicy
            </a>
        </div>
    );
};

export default Footer;
