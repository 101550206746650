import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AppLayout } from '../../components';
import { routes } from '../../constants';
import { NotFound, CreateUserAccount, Payment, Success } from '../../pages';
import PrivateRoute from '../PrivateRoute';

const AppRouter = () => {
    return (
        <BrowserRouter>
            <AppLayout>
                <Routes>
                    <Route path="*" element={<Navigate to={routes.NOT_FOUND} replace />} />
                    <Route path={routes.NOT_FOUND} element={<NotFound />} />
                    <Route path={routes.SIGNUP} element={<PrivateRoute />}>
                        <Route path={routes.SIGNUP} element={<CreateUserAccount />} />
                        <Route path={routes.PAYMENT} element={<Payment />} />
                        <Route path={routes.SUCCESS} element={<Success />} />
                    </Route>
                </Routes>
            </AppLayout>
        </BrowserRouter>
    );
};

export default AppRouter;
