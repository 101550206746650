import { createContext, useReducer, useEffect } from 'react';
import type { Dispatch, PropsWithChildren } from 'react';

import { BillingIntervals } from '@jaramba-frontend/core/types';
import { isProduction } from '@jaramba-frontend/core/utils';

import { CheckoutActions } from '../types';
import type { CheckoutErrors, ProductData } from '../types';

interface State {
    email: string | null;
    uid: number | null;
    errors: CheckoutErrors;
    billingInterval: BillingIntervals;
    token: string | null;
    productInfo: ProductData | null;
    isPaymentSucceed?: boolean;
}

interface ContextType {
    state: State;
    dispatch: Dispatch<Action>;
}

interface Action {
    type: CheckoutActions;
    payload: any;
}

const initialState: State = {
    email: null,
    uid: null,
    errors: null,
    billingInterval: BillingIntervals.Monthly,
    token: null,
    productInfo: null,
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case CheckoutActions.SetEmail:
            return { ...state, email: action.payload };
        case CheckoutActions.SetErrors:
            return { ...state, errors: action.payload };
        case CheckoutActions.SetBillingInterval:
            return { ...state, billingInterval: action.payload };
        case CheckoutActions.SetToken:
            return { ...state, token: action.payload };
        case CheckoutActions.SetUid:
            return { ...state, uid: action.payload };
        case CheckoutActions.SetProductInfo:
            return { ...state, productInfo: action.payload };
        case CheckoutActions.SetPaymentSuccess:
            return { ...state, isPaymentSucceed: action.payload };
        default:
            return state;
    }
};

const encodeState = (state: State): string => {
    const json = JSON.stringify(state);
    return isProduction() ? btoa(encodeURIComponent(json)) : json;
};

const decodeState = (encodedState: string): State => {
    const json = isProduction() ? decodeURIComponent(atob(encodedState)) : encodedState;
    return JSON.parse(json);
};

const rehydrateState = (): State => {
    if (window === undefined) {
        return initialState;
    }

    const storedState = sessionStorage.getItem('checkoutState');

    try {
        return storedState ? decodeState(storedState) : initialState;
    } catch {
        return initialState;
    }
};

export const CheckoutContext = createContext<ContextType>({
    state: initialState,
    dispatch: () => {},
});

export const CheckoutProvider = ({ children }: PropsWithChildren) => {
    const [state, dispatch] = useReducer(reducer, rehydrateState());

    useEffect(() => {
        sessionStorage.setItem('checkoutState', encodeState(state));
    }, [state]);

    return <CheckoutContext.Provider value={{ state, dispatch }}>{children}</CheckoutContext.Provider>;
};
