import { useEffect } from 'react';

import { CookieService } from '@jaramba-frontend/core/services';
import { isClient, extractMarketingUTMData } from '@jaramba-frontend/core/utils';

import { UTM_COOKIE_NAME } from '../constants';
import type { UtmData } from '../types';

const useMarketingData = () => {
    useEffect(() => {
        if (isClient() && !CookieService.get(UTM_COOKIE_NAME)) {
            const searchParams = new URLSearchParams(window.location.search);
            const marketingData = extractMarketingUTMData(searchParams);

            if (marketingData) {
                CookieService.set(UTM_COOKIE_NAME, JSON.stringify(marketingData), { path: '/' });
            }
        }
    }, []);

    const getStoredMarketingData = (): UtmData | undefined => {
        const utmCookie: UtmData | undefined = CookieService.get(UTM_COOKIE_NAME);
        return utmCookie;
    };

    return {
        getStoredMarketingData,
    };
};

export default useMarketingData;
